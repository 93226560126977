// Generated by Framer (8335dd8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["hAYbLQ65S", "IAkXemRos", "iJaOMBkat", "D9Lrj29jP", "a95U7vngx"];

const serializationHash = "framer-2naIe"

const variantClassNames = {a95U7vngx: "framer-v-17fkco8", D9Lrj29jP: "framer-v-1pxkfou", hAYbLQ65S: "framer-v-59wc3q", IAkXemRos: "framer-v-19jxvhu", iJaOMBkat: "framer-v-1fm15cl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "hAYbLQ65S", "Variant 2": "IAkXemRos", "Variant 3": "iJaOMBkat", "Variant 4": "D9Lrj29jP", "Variant 5": "a95U7vngx"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "hAYbLQ65S"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hAYbLQ65S", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-59wc3q", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hAYbLQ65S"} ref={ref ?? ref1} style={{background: "linear-gradient(180deg, rgba(33, 135, 219, 0) 50%, rgba(33, 135, 219, 0.8) 100%)", ...style}} variants={{a95U7vngx: {background: "linear-gradient(180deg, rgba(106, 161, 88, 0) 49%, rgba(106, 161, 88, 0.8) 100%)"}, IAkXemRos: {background: "linear-gradient(180deg, rgba(74, 47, 128, 0) 50%, rgba(74, 47, 128, 0.8) 100%)"}, iJaOMBkat: {background: "linear-gradient(180deg, rgba(255, 107, 107, 0) 50%, rgba(255, 107, 107, 0.8) 100%)"}}} {...addPropertyOverrides({a95U7vngx: {"data-framer-name": "Variant 5"}, D9Lrj29jP: {"data-framer-name": "Variant 4"}, IAkXemRos: {"data-framer-name": "Variant 2"}, iJaOMBkat: {"data-framer-name": "Variant 3"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2naIe.framer-94n2f9, .framer-2naIe .framer-94n2f9 { display: block; }", ".framer-2naIe.framer-59wc3q { height: 317px; overflow: hidden; position: relative; width: 471px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 317
 * @framerIntrinsicWidth 471
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"IAkXemRos":{"layout":["fixed","fixed"]},"iJaOMBkat":{"layout":["fixed","fixed"]},"D9Lrj29jP":{"layout":["fixed","fixed"]},"a95U7vngx":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerU6LGUeeHU: React.ComponentType<Props> = withCSS(Component, css, "framer-2naIe") as typeof Component;
export default FramerU6LGUeeHU;

FramerU6LGUeeHU.displayName = "haze";

FramerU6LGUeeHU.defaultProps = {height: 317, width: 471};

addPropertyControls(FramerU6LGUeeHU, {variant: {options: ["hAYbLQ65S", "IAkXemRos", "iJaOMBkat", "D9Lrj29jP", "a95U7vngx"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerU6LGUeeHU, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})